import React from "react"
import { AnimatePresence } from "framer-motion"
import { provideHeroContext, useHeroContext } from "./contexts/hero"
import Section from "~common/components/Section"
import Loading from "./components/Loading/Loading"

const Hero = provideHeroContext(
  React.memo(() => {
    const { height } = useHeroContext()

    const contentRef = React.useRef(null)
    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
      const load = async () => {
        const module = await import("./components/Content/Content")
        contentRef.current = module.default
        setIsLoading(false)
      }
      load()
    }, [])

    return (
      <Section color="secondary" sx={{ bg: "primary" }}>
        <Section.Body sx={{ minHeight: height || "100vh", pt: 0, pb: 0 }}>
          <AnimatePresence>{isLoading && <Loading />}</AnimatePresence>
          {!isLoading && <contentRef.current />}
        </Section.Body>
        <Section.Footer logo nasaBadge color="secondary" />
      </Section>
    )
  })
)

export default Hero
