import React from "react"

const useRotation = (min, max, period) => {
  const [index, setIndex] = React.useState(min)

  const indexRef = React.useRef(0)

  React.useEffect(() => {
    const next = old => {
      return old < max ? old + 1 : min
    }
    const timer = setInterval(() => {
      indexRef.current = next(indexRef.current)
      setIndex(indexRef.current)
    }, period)
    return () => clearInterval(timer)
  }, [setIndex, max, min, period])

  return index
}

export default useRotation
