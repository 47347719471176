import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Parallax from "~common/components/Parallax"
import Section from "~common/components/Section"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Cta from "~common/components/Cta"
import AnimationBox from "./AnimationBox"
import PlanetPerspective from "./PlanetPerspective"

const Intro = React.forwardRef((_, ref) => {
  const { mercuryPerspective, venusPerspective, marsPerspective, workOfArt } =
    useStaticQuery(graphql`
      {
        helio: file(relativePath: { eq: "almagesto-helio.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        geo: file(relativePath: { eq: "almagesto-geo.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        mercuryPerspective: file(
          relativePath: { eq: "almagesto-mercury.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        venusPerspective: file(relativePath: { eq: "almagesto-venus.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        marsPerspective: file(relativePath: { eq: "almagesto-mars.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        workOfArt: file(
          relativePath: { eq: "almagesto-work-of-art-prototype.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    `)

  return (
    <Section ref={ref} bg="secondary" color="primary">
      <Section.Header bg="secondary" color="primary">
        A change of perspective
      </Section.Header>
      <Section.Body
        sx={{
          bg: "secondary",
          alignItems: "center",
          justifyContent: "center",
          pt: [0, 0, 5],
          pb: 0,
        }}
      >
        <AnimationBox
          sx={{
            my: [5, 5, 0],
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
            gridTemplateAreas: [
              '"caption" "title" "animation" "comment"',
              '"caption" "title" "animation" "comment"',
              '"animation ." "animation caption" "animation title" "animation comment" "animation ."',
            ],
          }}
        >
          <AnimationBox.Caption>Heliocentric</AnimationBox.Caption>
          <AnimationBox.Title>
            This is how the orbits of the planets appear from the Sun
          </AnimationBox.Title>
          <AnimationBox.Comment>
            You already know it, it’s nice...
          </AnimationBox.Comment>
          <AnimationBox.Animation animation="IntroHelio" />
        </AnimationBox>
        <AnimationBox
          sx={{
            my: [5, 5, 0],
            gridTemplateAreas: [
              '"caption" "title" "animation" "comment"',
              '"caption" "title" "animation" "comment"',
              '". animation" "caption animation" "title animation" "comment animation" ". animation"',
            ],
          }}
        >
          <AnimationBox.Caption>Geocentric</AnimationBox.Caption>
          <AnimationBox.Title>
            These are the same paths as seen from the Earth
          </AnimationBox.Title>
          <AnimationBox.Comment>
            You may not be familiar with it, but this is what astronomers have
            observed and studied for centuries...
          </AnimationBox.Comment>
          <AnimationBox.Animation animation="IntroGeo" />
        </AnimationBox>
        <Box
          sx={{
            px: [5, "8%", "16%"],
            my: 5,
            display: "grid",
            placeItems: "center",
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr 1fr"],
            gridTemplateAreas: [
              '"title" "comment" "mercury" "venus" "mars"',
              '"title" "comment" "mercury" "venus" "mars"',
              '"title title title" "comment comment comment" "mercury-caption venus-caption mars-caption" "mercury venus mars"',
            ],
          }}
        >
          <Typography
            variant="large"
            sx={{
              gridArea: "title",
              color: "primary",
              textAlign: "center",
            }}
          >
            What about other planets' perspective?
          </Typography>
          <Box sx={{ gridArea: "comment", mb: 5, mx: [0, "25%"] }}>
            <Typography
              variant="normal"
              as="p"
              sx={{
                my: 3,
                textAlign: "center",
                color: "softened-secondary",
              }}
            >
              _
            </Typography>
            <Typography
              variant="normal"
              as="p"
              sx={{
                gridArea: "comment",
                fontWeight: 500,
                color: "primary",
                textAlign: "center",
              }}
            >
              Here's what you will find out.
            </Typography>
            <Typography
              variant="normal"
              as="p"
              sx={{
                gridArea: "comment",
                my: 3,
                color: "softened-secondary",
                textAlign: "center",
              }}
            >
              The following drawings show the Solar System during the same time
              span but from different frames of reference.
            </Typography>
          </Box>
          <PlanetPerspective
            name="mercury"
            image={getImage(mercuryPerspective)}
          />
          <PlanetPerspective
            name="venus"
            image={getImage(venusPerspective)}
            labelPosition="right"
          />
          <PlanetPerspective name="mars" image={getImage(marsPerspective)} />
        </Box>
        <Box
          sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          <Parallax
            bgImage={getImage(workOfArt)}
            bgImageAlt="almagesto poster"
            bgImageStyle={{
              width: ["250%", "150%", "100%"],
              left: ["-75%", "-25%", 0],
            }}
            visible={0.8}
            sx={{
              width: "100%",
            }}
          />
          <Box
            sx={{
              width: "100%",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              as="p"
              variant="normal"
              sx={{
                color: "secondary",
                fontStyle: "italic",
                width: ["66%", "50%", "33%", "25%"],
                mx: [5, 6, "16%"],
                my: [4, 5],
              }}
            >
              Explore the Solar System from an unusual point of view with a work
              of art, based on NASA ephemerides.
            </Typography>
            <Cta
              to="/shop/custom-poster"
              gtmParams={{ section: "A Change of Perspective" }}
              sx={{
                mx: ["auto", 6, "16%"],
                my: [5, 6],
                alignSelf: ["center", "flex-start"],
              }}
            >
              Explore the Solar System
            </Cta>
          </Box>
        </Box>
      </Section.Body>
      <Section.Footer logo nasaBadge color="primary" />
    </Section>
  )
})

export default Intro
