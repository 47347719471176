import React from "react"
import { Box } from "~common/components/Primitives"
import Logo from "~common/components/Logo"

const Loading = ({ isLoading, sx, ...rest }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100vh",
      ...sx,
    }}
    {...rest}
  >
    <Logo
      framer
      initial={{ opacity: 1, scale: 1, rotate: 360 }}
      animate={{
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
          rotate: {
            type: "inertia",
            velocity: 256,
          },
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          bounce: 0,
        },
      }}
      sx={{ width: 7, height: 7 }}
      color="secondary"
    />
  </Box>
)

export default Loading
