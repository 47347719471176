import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"

const PlanetPerspective = ({ name, image, labelPosition }) => (
  <>
    <Typography
      variant="small"
      as="h3"
      sx={{
        gridArea: [name, name, `${name}-caption`],
        color: "softened-secondary",
        textAlign: "center",
        textTransform: "uppercase",
        letterSpacing: "0.4em",
        fontSize: "0.7em",
        opacity: [1, 1, 0.5],
        fontWeight: [500, 500, 400],
        zIndex: [1, 1, 0],
        transform: [
          labelPosition === "right"
            ? "translateX(40vw)rotate(90deg)"
            : "rotate(-180deg)translateX(40vw)rotate(90deg)",
          labelPosition === "right"
            ? "translateX(28vw)rotate(90deg)"
            : "rotate(-180deg)translateX(28vw)rotate(90deg)",
          "none",
        ],
      }}
    >
      <Box as="span" sx={{ display: ["none", "none", "inline"], m: 3 }}>
        &#8226;
      </Box>
      from <wbr /> {name}
      <Box
        sx={{
          display: ["auto", "auto", "none"],
          lineHeight: 2,
          m: [2, 3, 0],
        }}
      >
        |
      </Box>
      <Box as="span" sx={{ display: ["none", "none", "inline"], m: 3 }}>
        &#8226;
      </Box>
    </Typography>
    <Box
      sx={{
        width: "100%",
        maxWidth: [400, 600],
        px: [5, 7, 4, 5],
        py: [3, 3, 4],
        gridArea: name,
      }}
    >
      <GatsbyImage image={image} alt={`${name} perspective`} />
    </Box>
  </>
)

export default PlanetPerspective
