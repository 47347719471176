import React from "react"
import Typography from "~common/components/Typography"

const Bullet = ({ i, sx, ...rest }) => (
  <Typography
    as="span"
    variant="heading2"
    sx={{
      mx: "auto",
      display: "block",
      color: "accent",
      textAlign: "center",
      ...sx,
    }}
    {...rest}
  >
    {i}.
  </Typography>
)

export default Bullet
