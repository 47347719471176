import React from "react"
import { useInView } from "react-intersection-observer"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Animation from "~common/components/Animation"

const AnimationBox = ({ children, sx, ...rest }) => (
  <Box
    sx={{
      px: [5, "8%", "12%", "16%"],
      display: "grid",
      placeItems: "center",
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

AnimationBox.Caption = ({ children, sx, ...rest }) => (
  <Typography
    variant="small"
    as="h2"
    sx={{
      gridArea: "caption",
      textTransform: "uppercase",
      letterSpacing: "0.4em",
      py: 4,
      textAlign: "center",
      color: "softened-secondary",
      ...sx,
    }}
    {...rest}
  >
    &#8226; {children} &#8226;
  </Typography>
)

AnimationBox.Title = ({ children, sx, ...rest }) => (
  <Typography
    variant="large"
    as="h3"
    sx={{
      gridArea: "title",
      color: "primary",
      textAlign: "center",
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
)

AnimationBox.Comment = ({ children, sx, ...rest }) => (
  <Typography
    variant="normal"
    as="p"
    sx={{
      gridArea: "comment",
      textAlign: "center",
      color: "softened-secondary",
      maxWidth: 640,
      ...sx,
    }}
    {...rest}
  >
    <Box as="span" sx={{ display: ["none", "none", "block"], my: [0, 0, 3] }}>
      _
    </Box>
    {children}
  </Typography>
)

const AnimationWrapper = function ({ animation, sx, ...rest }) {
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  return (
    <Box
      ref={ref}
      sx={{
        gridArea: "animation",
        p: [4, 5],
        width: ["80%", "50%", "32vw"],
        ...sx,
      }}
      {...rest}
    >
      <Animation
        animation={animation}
        play={inView}
        from={0.5}
        options={{ mass: 25, stiffness: 16, damping: 15 }}
      />
    </Box>
  )
}

AnimationBox.Animation = AnimationWrapper

export default AnimationBox
