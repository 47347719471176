import React from "react"
import useRotation from "~common/hooks/useRotation"
import useWindowSize from "~common/hooks/useWindowSize"
import HeroContext from "./context"

const planets = [
  // "mercury",
  "venus",
  "earth",
  "mars",
  // "jupiter",
  // "saturn",
  // "uranus",
  // "neptune",
]

export const HeroContextProvider = ({ children }) => {
  const index = useRotation(0, planets.length - 1, 2500)

  const planet = planets[index]

  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const ambientImageAspectRatio = 16 / 9

  const ambientImageWidth = Math.max(
    windowWidth,
    ambientImageAspectRatio * windowHeight
  )

  const posterWidthRatio = 0.16

  const posterWidth = posterWidthRatio * ambientImageWidth

  const posterHeight = (91 / 61) * posterWidth

  const width = windowWidth

  const height = Math.max(windowHeight, 2 * posterHeight)

  return (
    <HeroContext.Provider
      value={{
        planets,
        index,
        planet,
        width,
        height,
        posterWidth,
        posterHeight,
      }}
    >
      {children}
    </HeroContext.Provider>
  )
}

export const provideHeroContext = Component => props =>
  (
    <HeroContextProvider>
      <Component {...props} />
    </HeroContextProvider>
  )

export const useHeroContext = () => {
  const ctx = React.useContext(HeroContext)
  return ctx
}
