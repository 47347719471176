import React from "react"
import { Box } from "~common/components/Primitives"

const Video = ({ sx, ...rest }) => (
  <Box
    sx={{
      mx: "auto",
      aspectRatio: "16 / 9",
      maxWidth: 900,
      ...sx,
    }}
    {...rest}
  >
    {/* <iframe
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/bIQqUplWAhs?autoplay=1&mute=1"
      title="Celebrate your best memories with Almagesto"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe> */}
    <video
      width="100%"
      poster="almagesto-video-thumbnail.webp"
      autoPlay
      muted
      controls
      style={{ display: "block" }}
    >
      <source src="/almagesto-video-optimized.webm" type="video/webm" />
      <source src="/almagesto-video-optimized.mp4" type="video/mp4" />
      <track kind="caption" />
    </video>
  </Box>
)

export default Video
