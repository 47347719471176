import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import useSiteMetadata from "~common/hooks/useSiteMetadata";
import Page from "~common/components/Page";
import YourPersonalAstronomyPrint from "~sections/YourPersonalAstronomyPrint";
import Hero from "~sections/Hero";
import AChangeOfPerspective from "~sections/AChangeOfPerspective";
import ScienceMeetsDesign from "~sections/ScienceMeetsDesign";
import WhatMakesItSpecial from "~sections/WhatMakesItSpecial";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => {
  const {
    siteUrl,
    logo
  } = useSiteMetadata();
  return <Page seo={{
    title: "Personalized Astronomical Map Posters",
    schemaMarkup: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Almagesto",
      url: siteUrl,
      logo: logo,
      sameAs: ["https://www.facebook.com/AlmagestoDesign", "https://twitter.com/AlmagestoDesign", "https://www.instagram.com/almagesto.design/", "https://www.youtube.com/channel/UC2XuF0O8kNjdZBTt4ahnicA", "https://www.pinterest.it/AlmagestoDesign/"]
    }
  }}>
      {children}
    </Page>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Hero mdxType="Hero" />
    <YourPersonalAstronomyPrint mdxType="YourPersonalAstronomyPrint" />
    <AChangeOfPerspective mdxType="AChangeOfPerspective" />
    <ScienceMeetsDesign mdxType="ScienceMeetsDesign" />
    <WhatMakesItSpecial mdxType="WhatMakesItSpecial" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      