import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import Section from "~common/components/Section"
import Socials from "~common/components/Socials"
import Cta from "~common/components/Cta"
import Testimonial from "~common/components/Testimonial"
import Line from "~common/components/Line"
import Video from "./components/Video"
import Step from "./components/Step"

const YourPersonalAstronomyPrint = () => {
  const { mockup1, mockup2, mockup3 } = useStaticQuery(graphql`
    {
      mockup1: file(
        relativePath: { eq: "almagesto-astronomy-print-poster-art-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            breakpoints: [256, 512]
            quality: 25
          )
        }
      }
      mockup2: file(
        relativePath: { eq: "almagesto-astronomy-print-poster-art-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            breakpoints: [256, 512]
            quality: 25
          )
        }
      }
      mockup3: file(
        relativePath: { eq: "almagesto-astronomy-print-poster-art-3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            breakpoints: [256, 512]
            quality: 25
          )
        }
      }
    }
  `)

  return (
    <Section
      color="secondary"
      bg="primary"
      sx={{
        background: `url("almagesto-geometric-background.svg")`,
        backgroundPosition: "25% 33%",
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
      }}
    >
      <Section.Header color="accent" sx={{}}>
        Personalized
        <br /> Astronomical
        <br /> Poster
      </Section.Header>
      <Section.Body
        sx={{
          pt: 0,
        }}
      >
        <Video
          sx={{
            width: ["100%", "75%", "50%"],
          }}
        />
        <Typography
          as="h2"
          variant="heading2"
          sx={{
            color: "secondary",
            textAlign: ["center"],
            px: [5],
            my: [4, 5],
          }}
        >
          Astronomically Accurate. <br />
          Finely Designed.
        </Typography>
        <Typography
          as="p"
          variant="normal"
          sx={{
            color: "secondary",
            textAlign: ["center"],
            mx: "auto",
            my: [4, 5],
            px: [5],
            width: ["100%", "75%", "50%"],
            maxWidth: 640,
          }}
        >
          Celebrate your best memories through a unique art print that unfolds
          the movements of the Solar System as they appear from one of its
          planets.
        </Typography>
        <Line
          color="secondary"
          align="center"
          sx={{ mx: "auto", my: [5, 6], width: ["75%", "50%", "33%"] }}
        />
        <Typography
          variant="capitalized2"
          sx={{
            textAlign: "center",
            color: "secondary",
            mx: "auto",
            mt: [5, 6],
            mb: [4, 5],
          }}
        >
          How it works
        </Typography>
        <Box
          as="ul"
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "center",
            mx: [4, 4, "9%", "16%"],
            my: [4, 5],
          }}
        >
          <Step i={1} sx={{ mx: [2, 3, 4, 5], my: [3, 4] }}>
            Choose the reference planet, i.e.{" "}
            <Box as="span" sx={{ fontStyle: "italic" }}>
              your point of view
            </Box>
            . Then, choose your meaningful period by setting the starting and
            ending dates.
          </Step>
          <Step i={2} sx={{ mx: [2, 3, 4, 5], my: [3, 4] }}>
            Let Almagesto calculate the planets' orbits and preview them in
            seconds.
          </Step>
          <Step i={3} sx={{ mx: [2, 3, 4, 5], my: [3, 4] }}>
            Customize the final design by choosing the zoom level and the color
            palette. Optionally add a personal message.
          </Step>
        </Box>
        <Box sx={{ my: [4, 5], display: "flex", justifyContent: "center" }}>
          <Cta
            to="/shop/custom-poster"
            gtmParams={{ section: "Your Personal Astronomy Print" }}
          >
            Design your Almagesto
          </Cta>
        </Box>
        <Socials color="secondary" sx={{ my: [4, 5] }} />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: ["repeat(3, 1fr)"],
            gridGap: [1, 2],
            bg: "primary",
          }}
        >
          <GatsbyImage
            image={getImage(mockup1)}
            alt="almagesto astronomy print poster art"
            sizes="33vw"
          />
          <GatsbyImage
            image={getImage(mockup2)}
            alt="almagesto astronomy print poster art"
            sizes="33vw"
          />
          <GatsbyImage
            image={getImage(mockup3)}
            alt="almagesto astronomy print poster art"
            sizes="33vw"
          />
        </Box>
        <Typography
          as="p"
          variant="small"
          sx={{
            mx: "auto",
            my: [4, 5],
            px: [4, 5],
            width: ["100%", "75%", "50%"],
            maxWidth: 640,
            textAlign: "center",
          }}
        >
          Each Almagesto is a one-of-a-kind design poster, shaped by your
          personal choices of reference planet, starting and ending dates, zoom
          level, and color palette.
        </Typography>
        <Line
          color="secondary"
          align="center"
          sx={{ mx: "auto", my: [5, 6], width: ["75%", "50%", "33%"] }}
        />
        <Typography
          variant="capitalized3"
          sx={{
            textAlign: "center",
            color: "secondary",
            mx: "auto",
            my: [5, 6],
            pt: 2,
          }}
        >
          What they say about us
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: [
              "1fr",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ],
            alignItems: "start",
            justityItems: "center",
            gridGap: [4, 5],
            mx: "auto",
            my: [4, 5],
            px: [4, 5, "auto"],
            width: ["100%", "100%", "90%", "80%"],
          }}
        >
          <Testimonial>
            <Testimonial.Text>
              Having your past and your universe in your living room is a kind
              of a dream and you can make it with a click!
            </Testimonial.Text>
            <Testimonial.Author>Valentina</Testimonial.Author>
          </Testimonial>
          <Testimonial
            sx={{
              gridRow: ["auto", 2, 2, "auto"],
              gridColumn: ["auto", "1 / 3", "1 / 3", "auto"],
              justifySelf: "center",
            }}
          >
            <Testimonial.Text>
              I was looking for the perfect gift for my friend who is a huge
              astronomy nerd and found Almagesto. I'm impressed with the way it
              was designed and the concept of switching the perspective on the
              Solar System is literally from another planet!
            </Testimonial.Text>
            <Testimonial.Author>Luca</Testimonial.Author>
          </Testimonial>
          <Testimonial sx={{ gridRow: ["auto", 1, 1, "auto"] }}>
            <Testimonial.Text>
              Who knew that some orbital patterns would make for an awesome,
              minimal wall art? Apparently you did! Great job!
            </Testimonial.Text>
            <Testimonial.Author>David</Testimonial.Author>
          </Testimonial>
        </Box>
      </Section.Body>
      <Section.Footer logo nasaBadge />
    </Section>
  )
}

export default YourPersonalAstronomyPrint
